<script lang="ts" setup>
const { $TolgeeProvider } = useNuxtApp();
</script>

<template>
  <component :is="$TolgeeProvider">
    <div class="flex h-screen">
      <div class="m-auto">
        <slot />
      </div>
    </div>
  </component>
</template>
